<template>
  <div class="wrap-content">
    <div class="header" :class="{ fixed: isFiexd == true }">
      <div class="return">
        <div class="return-icon" @click="handleBack">
          <Icon type="ios-arrow-back" />
        </div>
        <span>数据目录复核</span>
        <span></span>
      </div>
      <ul>
        <li
          :class="navName == item.value ? 'active' : ''"
          v-for="(item, i) in nav"
          :key="i"
          :id="'info' + i"
        >
          <a href="javascript:void(0)" @click="goAnchor(item.value)">{{
            item.title
          }}</a>
        </li>
      </ul>
      <div class="button">
        <Button class="margin-right-10 btn-error" @click="handleError"
          >驳回</Button
        >
        <Button class="btn-success" @click="modal1 = true">通过</Button>
        <CommonHeader />
      </div>
    </div>
    <div class="banner">
      <img
        src="../../assets/img/重要签.png"
        alt=""
        class="important-icon"
        v-if="cache.level == '重要数据'"
      />
      <img
        src="../../assets/img/核心签.png"
        alt=""
        class="important-icon"
        v-if="cache.level == '核心数据'"
      />
      <img
        src="../../assets/img/一般签.png"
        alt=""
        class="important-icon"
        v-if="cache.level == '一般数据'"
      />
      <div class="banner-content">
        <h2>{{ formBasic.dataName }}</h2>
        <ul>
          <li>
            <span>所属机构：</span><span>{{ cache.submitInst }}</span>
          </li>
          <li>|</li>
          <!-- <li>
            <span>复核机构：</span><span>{{ cache.auditAgency }}</span>
          </li>
          <li>|</li> -->
          <li>
            <span>填报人：</span><span>{{ cache.submitter }}</span>
          </li>
          <li>|</li>
          <li>
            <span>复核人：</span><span v-if="cache.auditor">{{ cache.auditor.username }}</span>
          </li>
          <li>|</li>
          <li>
            <span>复核通过时间：</span><span>{{ cache.auditPassData }}</span>
          </li>
          <li>|</li>
          <li>
            <span>操作记录</span
            ><span class="bold" @click="handleRecord">{{ operateCount }}</span
            >条
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="main-content">
        <div class="info" id="basic">
          <div class="title margin-20">
            <img src="../../assets/img/基本信息-已填写.png" alt="" />
            <span>基本信息</span>
          </div>
          <div class="form">
            <Form
              ref="formBasicValidate"
              :model="formBasic"
              :label-width="250"
              label-position="left"
            >
              <FormItem label="数据名称" prop="dataName">
                <div>{{ formBasic.dataName }}</div>
              </FormItem>

              <FormItem label="一级分类" prop="first">
                <div>{{ formBasic.first }}</div>
              </FormItem>
              <FormItem label="二级分类" prop="second">
                <div>{{ formBasic.second }}</div>
              </FormItem>
              <FormItem label="三级分类">
                <div>{{ formBasic.third }}</div>
              </FormItem>
              <FormItem label="数据四级类别">
                <div>{{ formBasic.fourth }}</div>
              </FormItem>
              <FormItem label="数据描述">
                <div>{{ formBasic.description }}</div>
              </FormItem>
              <FormItem label="数据级别">
                <div>{{ formBasic.level }}</div>
              </FormItem>
              <FormItem label="分级依据">
                <div>{{ formBasic.gradeBasic }}</div>
              </FormItem>
              <FormItem label="所在数据中心">
                <div>{{ formBasic.dataCenter }}</div>
              </FormItem>
              <FormItem label="数据总量">
                <div>{{ formBasic.dataSize }}GB</div>
              </FormItem>
              <FormItem label="数据记录数">
                <div>{{ formBasic.dataCount }}</div>
              </FormItem>
              <FormItem label="数据主要来源">
                <div>{{ formBasic.source }}</div>
              </FormItem>
              <FormItem label="数据精度">
                <div>{{ formBasic.precision }}</div>
              </FormItem>
              <FormItem label="覆盖类型">
                <div>{{ formBasic.cover }}</div>
              </FormItem>
              <FormItem label="覆盖具体情况">
                <div>{{ formBasic.coverDetail }}</div>
              </FormItem>
              <FormItem label="覆盖占比">
                <div>{{ formBasic.ratio }}</div>
              </FormItem>
              <FormItem label="覆盖占比测算依据">
                <div>{{ formBasic.ratioCalculationBasis }}</div>
              </FormItem>
            </Form>
          </div>
        </div>
        <div class="info" id="part">
          <div class="title margin-20">
            <img src="../../assets/img/责任主体-已填写.png" alt="" />
            <span>数据载体情况</span>
          </div>
          <div class="form">
            <Form
              ref="formPartValidate"
              :model="formPart"
              :label-width="250"
              label-position="left"
            >
              <FormItem label="数据载体">
                <div>{{ formPart.payload }}</div>
              </FormItem>
              <FormItem label="信息系统名称">
                <div>{{ formPart.appName }}</div>
              </FormItem>
              <FormItem label="服务网站域名">
                <div>{{ formPart.domainName }}</div>
              </FormItem>
              <FormItem label="服务网站IP地址">
                <div>{{ formPart.webIpAddress }}</div>
              </FormItem>
              <FormItem label="服务网站IP端口">
                <div>{{ formPart.webIpPort }}</div>
              </FormItem>
              <FormItem label="主机IP地址">
                <div>{{ formPart.hostIpAddress }}</div>
              </FormItem>
              <FormItem label="主机端口">
                <div>{{ formPart.hostIpPort }}</div>
              </FormItem>
              <FormItem label="是否进行ICP备案">
                <div>{{ formPart.icpFilings }}</div>
              </FormItem>
              <FormItem label="网络安全等级保护情况">
                <div>{{ formPart.protection }}</div>
              </FormItem>
              <FormItem label="是否为关键信息基础设施">
                <div>{{ formPart.infrastructure }}</div>
              </FormItem>
            </Form>
          </div>
        </div>
        <div class="info" id="dealWith">
          <div class="title margin-20">
            <img src="../../assets/img/处理情况-已填写.png" alt="" />
            <span>数据共享及出境情况</span>
          </div>
          <div class="form">
            <Form
              ref="formSafeValidate"
              :model="formDealWith"
              :label-width="250"
              label-position="left"
            >
              <FormItem label="数据处理目的">
                <div>{{ formDealWith.purpose }}</div>
              </FormItem>
              <FormItem label="是否涉及跨主体流动">
                <div>{{ formDealWith.flows }}</div>
              </FormItem>
              <FormItem label="共享方式">
                <div>{{ formDealWith.sharingMode }}</div>
              </FormItem>
              <FormItem label="共享范围">
                <div>{{ formDealWith.shareRange }}</div>
              </FormItem>
              <FormItem label="是否有涉外数据">
                <div>{{ formDealWith.foreign }}</div>
              </FormItem>
              <FormItem label="涉外数据来源情况">
                <div>{{ formDealWith.foreignDataSource }}</div>
              </FormItem>
              <FormItem label="是否出境">
                <div>{{ formDealWith.abroad }}</div>
              </FormItem>

              <FormItem label="出境方式">
                <div>{{ formDealWith.exitMode }}</div>
              </FormItem>
              <FormItem label="境外接收方">
                <div>{{ formDealWith.overseasReceiver }}</div>
              </FormItem>

              <FormItem label="是否完成数据出境安全评估">
                <div>{{ formDealWith.safetyAssessment }}</div>
              </FormItem>
              <FormItem label="数据出境安全评估结果">
                <div>{{ formDealWith.assessmentResult }}</div>
              </FormItem>
            </Form>
          </div>
        </div>
        <div class="info" id="safe">
          <div class="title margin-20">
            <img src="../../assets/img/数据安全-已填写.png" alt="" />
            <span>数据安全情况</span>
          </div>
          <div class="form">
            <Form
              ref="formSafeValidate"
              :model="formSafe"
              :label-width="250"
              label-position="left"
            >
              <FormItem label="姓名">
                <div>{{ formSafe.chargeName }}</div>
              </FormItem>
              <FormItem label="职务">
                <div>{{ formSafe.chargeTitle }}</div>
              </FormItem>
              <FormItem label="联系方式">
                <div>{{ formSafe.chargeContact }}</div>
              </FormItem>
              <FormItem label="是否进行数据安全风险评估">
                <div>{{ formSafe.assessment }}</div>
              </FormItem>

              <FormItem label="评估机构">
                <div>{{ formSafe.evaluationOrg }}</div>
              </FormItem>
              <FormItem label="评估依据规范">
                <div>{{ formSafe.specification }}</div>
              </FormItem>
              <FormItem label="评估时间">
                <div>{{ formSafe.evaluationDate }}</div>
              </FormItem>
              <FormItem label="评估结论">
                <div>{{ formSafe.conclusion }}</div>
              </FormItem>
            </Form>
          </div>
        </div>
        <div class="info" id="duty">
          <div class="title margin-20">
            <img src="../../assets/img/数据安全-已填写.png" alt="" />
            <span>责任主体情况</span>
          </div>
          <div class="form">
            <Form
              ref="formDutyValidate"
              :model="formDuty"
              :label-width="250"
              label-position="left"
            >
              <FormItem label="数据处理者名称">
                <div>{{ formDuty.processor }}</div>
              </FormItem>
              <FormItem label="机构代码">
                <div>{{ formDuty.orgCode }}</div>
              </FormItem>
              <FormItem label="省份">
                <div>{{ formDuty.province }}</div>
              </FormItem>
              <FormItem label="市">
                <div>{{ formDuty.city }}</div>
              </FormItem>
            </Form>
          </div>
        </div>
        <div class="info" id="audit">
          <div class="title margin-20">
            <img src="../../assets/img/数据安全-已填写.png" alt="" />
            <span>数据目录审核情况</span>
          </div>
          <div class="form">
            <Form
              ref="formAuditValidate"
              :model="formAudit"
              :label-width="250"
              label-position="left"
            >
              <FormItem label="是否经过专家评审">
                <div>{{ formAudit.reviewed }}</div>
              </FormItem>
              <FormItem label="是否经过学科领域专家评审">
                <div>{{ formAudit.fieldReviewed }}</div>
              </FormItem>
              <FormItem label="是否经过数据管理专家评审">
                <div>{{ formAudit.managerReviewed }}</div>
              </FormItem>
              <FormItem label="是否经过信息化管理专家评审">
                <div>{{ formAudit.informationReviewed }}</div>
              </FormItem>

              <FormItem label="是否经过法定代表人审定">
                <div>{{ formAudit.legalReviewed }}</div>
              </FormItem>
            </Form>
          </div>
        </div>
        <div class="info" id="remark">
          <div class="title margin-20">
            <img src="../../assets/img/备注补充-已填写.png" alt="" />
            <span>备注补充</span>
          </div>
          <div class="form remark">
            <label for="" style="width: 250px">备注</label>
            <div>{{ remark }}</div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <BackTop :bottom="100"></BackTop>
    <reject ref="rejectRef" :id="id" />
    <Record />
    <Modal
      v-model="modal1"
      sticky
      scrollable
      class="custom-modal-footer-block"
      @on-cancel="modal1 = false"
    >
      <template #header>
        <p class="title">提醒</p>
        <div class="modal-close" @click="modal1 = false">
          关闭 <span>ESC</span>
        </div>
      </template>
      <div class="alarm-info">确认要通过吗？</div>
      <template #footer>
        <div class="btn btn-default" @click="handleThough">确认</div>
      </template>
    </Modal>
  </div>
</template>
  <script setup>
import {
  reactive,
  ref,
  watch,
  onMounted,
  nextTick,
  onBeforeUnmount,
  inject,
} from "vue";
import province from "@/utils/province";
import { Message, Modal } from "view-ui-plus";
import Footer from "@/components/footer.vue";
import reject from "./components/reject.vue";
import Record from "./components/record.vue";
import { useRouter, useRoute } from "vue-router";
import CommonHeader from "@/components/commonHeader.vue";
import qs from "qs";
import bus from "@/utils/bus";

import { ElLoading } from "element-plus";
let id = ref("");
let level = ref(0);
let modal1 = ref(false);
let cache = ref({}); //当前数据
let router = useRouter();
let route = useRoute();
const handleBack = () => {
  router.back();
};
//导航信息
let nav = [
  {
    title: "基本信息",
    value: "basic",
  },
  {
    title: "载体情况",
    value: "part",
  },
  {
    title: "共享及出境情况",
    value: "dealWith",
  },
  {
    title: "数据安全",
    value: "safe",
  },
  {
    title: "责任主体",
    value: "duty",
  },
  {
    title: "目录审核情况",
    value: "audit",
  },
  {
    title: "备注补充",
    value: "remark",
  },
];

//验证数据是否填写完整

let formBasic = ref({
  dataName: "",
  first: "",
  second: "",
  third: "",
  fourth: "",
  description: "",
  level: "核心数据",
  gradeBasic: "",
  dataCenter: "",
  source: "",
  dataSize: "",
  dataCount: "",
  cover: "",
  ratio: "",
  precision: "",
  coverDetail: "",
  ratioCalculationBasis: "",
});

let formPart = ref({
  payload: "",
  appName: "",
  domainName: "",
  webIpAddress: "",
  webIpPort: "",
  hostIpAddress: "",
  hostIpPort: "",
  icpFilings: "",
  protection: "",
  infrastructure: "",
});

let formDealWith = ref({
  purpose: "",
  abroad: "",
  foreignDataSource: "",
  exitMode: "",
  flows: "",
  foreign: "",
  sharingMode: "",
  shareRange: "",
  foreignDataSource: "",
  overseasReceiver: "",
  safetyAssessment: "",
  assessmentResult: "",
});
let formSafe = ref({
  chargeName: "",
  chargeTitle: "",
  chargeContact: "",
  assessment: "",
  evaluationOrg: "",
  specification: "",
  evaluationDate: "",
  conclusion: "",
});
let formDuty = ref({
  processor: "",
  orgCode: "",
  province: "",
  city: "",
});
let formAudit = ref({
  reviewed: "",
  fieldReviewed: "",
  managerReviewed: "",
  informationReviewed: "",
  legalReviewed: "",
});
let remark = ref("");
let isCompleteRemark = ref(false);
let citys = ref([]);
//选择省份修改市区
const changeProvince = (val) => {
  province.forEach((item) => {
    if (item.name == val) {
      citys.value = item.city;
    }
  });
};

let axios = inject("axios");

//锚点跳转,并切换导航栏选中项
let navName = ref("basic");
const goAnchor = (e) => {
  navName.value = e;
  var id = "#" + e;
  document.querySelector(id).scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
};

let isFiexd = ref(false); //判断顶部导航位置是否要固定

//添加滚动事件
const addScrollFun = () => {
  let el = document.getElementById("wrap");
  el.addEventListener("scroll", listenScroll); // 监听页面滚动
};

const listenScroll = (e) => {
  let scrollTop0 = document.getElementById("basic").offsetTop - 200;
  let scrollTop1 = document.getElementById("part").offsetTop - 200;
  let scrollTop2 = document.getElementById("dealWith").offsetTop - 200;
  let scrollTop3 = document.getElementById("safe").offsetTop - 200;
  let scrollTop4 = document.getElementById("duty").offsetTop - 200;
  let scrollTop5 = document.getElementById("audit").offsetTop - 200;
  let scrollTop6 = document.getElementById("remark").offsetTop - 200;
  // var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  var scrollTop = document.getElementsByClassName("wrap-content")[0].scrollTop;
  // console.log(scrollTop);
  if (scrollTop < scrollTop1) {
    navName.value = "basic";
  }
  if (scrollTop >= scrollTop1 && scrollTop < scrollTop2) {
    navName.value = "part";
  }
  if (scrollTop >= scrollTop2 && scrollTop < scrollTop3) {
    navName.value = "dealWith";
  }
  if (scrollTop >= scrollTop3 && scrollTop < scrollTop4) {
    navName.value = "safe";
  }
  if (scrollTop >= scrollTop4 && scrollTop < scrollTop5) {
    navName.value = "duty";
  }
  if (scrollTop >= scrollTop5 && scrollTop < scrollTop6) {
    navName.value = "audit";
  }
  if (scrollTop >= scrollTop6) {
    navName.value = "remark";
  }

  if (scrollTop > 200) {
    isFiexd.value = true;
  } else {
    isFiexd.value = false;
  }
};

//复核通过
const handleThough = () => {
  let url = `/audit/audit`;
  let params = {
    dataId: id.value,
    other: "",
    result: "复核通过",
    questions: "",
  };
  openFullScreen();
  axios.post(url, qs.stringify(params)).then((res) => {
    Loading.value.close();
    if (res.data.code == 200) {
      Message.success("复核通过");
      router.push({ name: "examineList" });
    } else {
      Modal.error({
        title: "消息警告",
        content: res.data.message,
      });
    }
  });
};
//复核驳回,点击驳回按钮控制驳回弹框出现
const rejectRef = ref();
const handleError = () => {
  rejectRef.value.modify();
};

//点击查看审批记录
const handleRecord = () => {
  bus.emit("openHistory", id.value);
};
//查询操作记录总条数
let operateCount = ref(0);
const getOperateCount = () => {
  let url = `/content/operateCount?dataId=${id.value}`;
  axios.get(url).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
      operateCount.value = res.data.data;
    }
  });
};

onMounted(() => {
  nextTick(() => {
    addScrollFun();
  });
  let query = route.query;
  if (query.id) {
    id.value = query.id;
    level.value = query.level;
    getDataDetails();
    getAuditDate();
    getOperateCount();
  }
});
//查询暂存详情
const getDataDetails = () => {
  let url = `/content/info?dataId=${id.value}`;
  openFullScreen();
  axios.get(url).then((res) => {
    Loading.value.close();
    if (res.data.code == 200) {
      let data = res.data.data;
      for (let key in data) {
        if (data[key] == "" || data[key] == null) {
          data[key] = "无";
        }
      }
      cache.value = {
        ...data,
        reviewer: Array.isArray(data.reviewer)
          ? data.reviewer.join("；")
          : null,
        auditAgency: Array.isArray(data.auditAgency)
          ? data.auditAgency.join("；")
          : null,
      };

      id.value = data.id;
      formBasic.value = {
        dataName: data.dataName,
        // standard: data.standard,
        first: data.first,
        // second: data.second != null ? data.second.toString() : null,
        second: Array.isArray(data.second) ? data.second.join("；") : null,
        third: Array.isArray(data.third) ? data.third.join("；") : null,
        fourth: data.fourth,
        description: data.description,
        level: data.level,
        gradeBasic: data.gradeBasic,
        dataCenter: data.dataCenter,
        coverDetail: data.coverDetail,
        source: data.source,
        dataSize: data.dataSize,
        dataCount: data.dataCount,
        cover: data.cover,
        ratio: data.ratio,
        precision: data.precision,
        ratioCalculationBasis: data.ratioCalculationBasis,
      };

      formPart.value = {
        payload: data.payload,
        appName: data.appName,
        domainName: data.domainName,
        webIpAddress: data.webIpAddress,
        webIpPort: data.webIpPort,
        hostIpAddress: data.hostIpAddress,
        hostIpPort: data.hostIpPort,
        icpFilings: data.icpFilings,
        protection: data.protection,
        infrastructure: data.infrastructure,
      };

      formDealWith.value = {
        purpose: data.purpose,
        abroad: data.abroad,
        foreignDataSource: data.foreignDataSource,
        exitMode: data.exitMode,
        flows: data.flows,
        foreign: data.foreign,
        sharingMode: data.sharingMode,
        shareRange: data.shareRange,
        foreignDataSource: data.foreignDataSource,
        overseasReceiver: data.overseasReceiver,
        safetyAssessment: data.safetyAssessment,
        assessmentResult: data.assessmentResult,
      };
      formSafe.value = {
        chargeName: data.chargeName,
        chargeTitle: data.chargeTitle,
        chargeContact: data.chargeContact,
        assessment: data.assessment,
        evaluationOrg: data.evaluationOrg,
        specification: data.specification,
        evaluationDate: data.evaluationDate,
        conclusion: data.conclusion,
      };
      formDuty.value = {
        processor: data.processor,
        orgCode: data.orgCode,
        province: data.province,
        city: data.city,
      };
      //控制市区的显示
      changeProvince(data.province);
      formAudit.value = {
        reviewed: data.reviewed,
        fieldReviewed: data.fieldReviewed,
        managerReviewed: data.managerReviewed,
        informationReviewed: data.informationReviewed,
        legalReviewed: data.legalReviewed,
      };
      remark.value = data.remark;
    }
  });
};

let auditThroughTime = ref("");
//查询复核通过时间
const getAuditDate = () => {
  let url = `/content/auditInfo?dataId=${id.value}`;
  axios.get(url).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
      auditThroughTime.value = res.data.data;
    }
  });
};

const Loading = ref(null);
const openFullScreen = () => {
  Loading.value = ElLoading.service({
    lock: true,
    background: "rgba(255, 255,255, 0.5)",
  });
};


onBeforeUnmount(() => {
  document.getElementById("wrap").removeEventListener("scroll", listenScroll);
});
</script>
  
  <style lang="scss" scoped>
@import "./details.scss";
.wrap-content {
  padding: 10px 30px;
  background: #fff;
  height: 100vh;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // line-height: 70px;
  .return {
    display: flex;
    align-items: center;
    .return-icon {
      width: 24px;
      height: 24px;
      border: solid 2px #111111;
      border-radius: 6px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      cursor: pointer;
      i {
        font-size: 18px;
        color: #000;
      }
    }
    span {
      font-family: PingFangSC-Medium;
      font-size: 26px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 33px;
      color: #111111;
    }
    span:last-child {
      color: #cccccc;
    }
  }
  > ul {
    display: flex;
    li {
      font-size: 16px;
      margin-right: 20px;
      cursor: pointer;
      line-height: 70px;
      position: relative;
      a {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 46px;
        letter-spacing: 0px;
        color: #555555;
      }
    }
    li.active {
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 60px;
        height: 3px;
        background-color: #c22b22;
        transform: translate(-50%, -50%);
      }
      a {
        color: #c22b22;
        font-weight: bold;
      }
    }
  }
}
.fixed {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  //  height: ;
  width: calc(100% - 200px);
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  background: #fff;
  padding: 0 30px;
  > ul {
    li.active {
      &::before {
        bottom: -2px;
      }
    }
  }
}
.main {
  .title {
    font-family: PingFangSC-Medium;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 33px;
    letter-spacing: 0px;
    color: #111111;
    font-weight: bold;
    display: flex;
    align-items: center;
    img {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
}

.remark {
  display: flex;
  label {
    font-size: 14px;
    display: block;
    min-width: 80px;
    margin-bottom: 10px;
    width: 250px;
  }
  > div {
    width: calc(100% - 250px);
    font-family: PingFangSC-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 30px;
    letter-spacing: 0px;
    color: #111111;
  }
}
.footer {
  padding-top: 50px;
  border-top: 1px solid #ddd;
}
.button {
  display: flex;
  align-items: center;
  > div {
    margin-left: 10px;
  }
  .btn {
    color: #fff;
    cursor: pointer;
    i {
      padding-right: 5px;
    }
  }
  .icon {
    img {
      display: block;
      width: 20px;
      height: 18px;
    }
  }
}

//标题logo
.title-logo {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.main {
  display: flex;
  padding-bottom: 50px;
  .main-content {
    width: 100%;
  }
}
.btn {
  padding: 0 25px;
}
.form {
  ::v-deep .ivu-form {
    .ivu-form-item {
      margin-bottom: 0;
    }
    .ivu-form-item-content {
      div {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 50px;
        letter-spacing: 0px;
        color: #111111;
      }
    }
  }
}
.banner {
  background: url(../../assets/img/目录详情顶部banner.png) no-repeat;
  height: 200px;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -30px;
  .important-icon {
    display: block;
    width: 50px;
    height: 48px;
    position: absolute;
    top: 15px;
    right: 30px;
  }
  .banner-content {
    text-align: center;
    h2 {
      color: #111111;
      font-family: PingFangSC-Regular;
      font-size: 30px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 33px;
      margin-bottom: 30px;
    }
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        color: #555555;
        margin: 0 10px;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
}
</style>